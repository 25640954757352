<template>
  <div class="resourceVacantList">
    <Back title="空置房源列表"></Back>
    <main>
      <van-tabs class="tabs" border swipeable color="#4F94F3" v-model:active="active" @change="onResourceType(active)">
        <van-tab v-for="item in resourceTypeOpt" :title="item.typeName + '(' + item.count + ')'" :key="item.typeVal">
          <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <div class="list-item font-14" v-for="i in list" :key="i.resourceId">
              <p class="van-hairline--bottom">
                <van-icon class="blue" name="wap-home-o" />
                {{ i.projectName }}-{{ i.unitName }}-{{ i.resourceName }}
              </p>
              <p class="area">
                <span><span class="grey">面积：</span>{{ i.area }}㎡</span>
                <span><span class="grey">退租日期：</span>{{ i.expDate }}</span>
              </p>
            </div>
          </van-list>
        </van-tab>
      </van-tabs>
    </main>
  </div>
</template>

<script>
import Back from "@/components/back";
import { getResourceVacantList, getResourceCount } from "@/api/resource/resource"
export default {
  components: { Back },
  data() {
    return {
      active: 0,
      params: {
        page: 1,
        limit: 10,
        cubeType: 1,
      },
      list: [],
      loading: false,
      finished: false, //是否结束加载，切换状态后记得开启
      resourceTypeOpt: [
        {
          typeName: "空置",
          typeVal: 1,
          count: "",
        },
        {
          typeName: "审批中",
          typeVal: 2,
          count: "",
        },
        {
          typeName: "待起租",
          typeVal: 3,
          count: "",
        },
      ],
    };
  },
  mounted() {
    this.getResourceVacantList();
    this.getResourceCount();
  },

  methods: {
    // 【监听】空置已租切换
    onResourceType(val) {
      this.params.cubeType = val + 1;
      this.reset();
    },

    // 【请求】获取房源列表
    getResourceVacantList() {
      let data = this.params;
      getResourceVacantList(data).then((res) => {
        if (res.success) {
          if (res.data) {
            this.list = [...this.list, ...res.data];
            this.loading = false;
            if (this.list.length >= res.count) {
              this.finished = true;
            }
          } else {
            this.loading = false;
            this.finished = true;
          }
        } else {
          this.loading = false;
          this.finished = true;
        }
      });
    },
    // 【请求】获取房源数量
    getResourceCount() {
      let data = this.params;
      getResourceCount(data).then((res) => {
        if (res.success) {
          this.resourceTypeOpt[0].count = res.data.vacancyCount;
          this.resourceTypeOpt[1].count = res.data.approveCount;
          this.resourceTypeOpt[2].count = res.data.leaseCount;
        }
      });
    },
    // 【请求】下拉加载
    onLoad() {
      this.params.page++;
      this.getResourceVacantList();
    },
    // 状态变更重置
    reset() {
      this.list = [];
      this.params.page = 1;
      this.finished = false;
      this.getResourceVacantList();
    },

  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/theme';

.resourceVacantList {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  overflow: auto;
  background: #F2F1F6;

}

.list-item {
  background: #fff;
  padding: .1rem;
  margin: .1rem;
  border-radius: .06rem;

  p {
    padding: .08rem 0;
    display: flex;
    align-items: center;
  }
}

.blue {
  color: #4F94F3;
  font-size: .18rem;
  font-weight: bold;
  margin-right: .03rem;
}

.area {
  display: flex;
  justify-content: space-between;
}

.font-12 {
  font-size: .12rem;
}

.font-14 {
  font-size: .14rem;
}

.grey {
  color: #666666;
}
</style>

<style  lang="scss">
.resourceVacantList {
  .van-tab {
    background: #F2F1F6 !important;
  }
}
</style>